import React from 'react';
import "./LineHeadMenu.scss";
import IconMenu from "assets/images/sk-menu.svg";
import Logotipo from 'assets/images/sankhya-logotipo.svg';
import BtnSank from './BtnSank';

export const LineHeadMenu = () => {
    return (
        <>
            <div className="sk-head-relative">
                <div className="sk-head-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <nav className="navbar navbar-expand-lg sk-head-menu">

                                    <a className="navbar-brand" href="/">
                                        <img src={Logotipo} alt="Logotipo" />
                                    </a>
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span><img src={IconMenu} alt="menu"/></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                            <li className="nav-item">
                                                <a className="nav-link" aria-current="page" href="#contato">Contato</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link d-lg-none" aria-current="page" href="#contato">Colaborador Sankhya</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link d-lg-none" aria-current="page" href="#contato">Cliente</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="btns-login">
                                        <BtnSank url="/#colaborador" text="Colaborador Sankhya" myClass="btn-white" />
                                        <BtnSank url="/#cliente" text="Cliente" myClass="btn-white" />
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
