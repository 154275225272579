import React from 'react';
import "./BlogSank.scss";
import "./microComponents/CarouselNews";
import CarouselNews from './microComponents/CarouselNews';


const BlogSank = (props) => {


    return (
        <>
        <section className="blog-sank" id={props.id}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title-uni two-color">
                            <h1>O que nossos alunos dizem sobre a Universidade Sankhya</h1>
                        </div>
                        <CarouselNews />
                    </div>
                </div>
            </div>
        </section>

        </>
    )
}

export default BlogSank
