import React from 'react';
import HeaderPublic from "./microComponents/HeaderPublic";
import Banner from "./microComponents/Banner";
import './App.scss';
import BlogSank from "./BlogSank";
import Universidade from "./Universidade";
import ContactSk from './microComponents/ContactSk';
import FooterSank from './FooterSank';

export default () => {
  return (
    <>
        <HeaderPublic />
        <Banner />
        <Universidade id="universidade" />
        <BlogSank id="blog" />
        <ContactSk id="contato" />
        <FooterSank />
    </>
  );
};

