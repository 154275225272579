import React from 'react'
import LogoNews01 from "assets/images/depoimentos/dep01.jpg";
import LogoNews02 from "assets/images/depoimentos/dep02.jpg";
import LogoNews03 from "assets/images/depoimentos/dep03.jpg";
import LogoNews04 from "assets/images/depoimentos/dep04.jpg";


import QuoteLeft from "assets/images/quote-marks.png";
import QuoteRight from "assets/images/quote-marks-02.png";

import "./CarouselNews.scss";

const CarouselNews = () => {
    return (
        <>
            <div id="news-carousel" className="carousel carousel-fade slide"
                data-bs-ride="carousel-news">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#news-carousel"
                        data-bs-slide-to="0" className="active"
                        aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#news-carousel"
                        data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#news-carousel"
                        data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#news-carousel"
                        data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div className="carousel-inner carousel-news-wrap">
                    <div className="carousel-item active" data-bs-interval="3000">
                        <div className="wrap-carousel">
                            <div className="container">
                                <div className="row gx-5">
                                    <div className="col-12 col-sm-4 col-md-4">
                                        <div className="wrap-image-news">
                                            <img src={LogoNews01} alt="..." />
                                        </div>
                                    </div>
                                    <div className="col-12 col-8 col-sm-8 col-md-8">
                                        <div className="caption-news">
                                            <div className="aspa-left"> <img src={QuoteLeft} /></div>
                                            <p>
                                                Antes de começarmos a implantar tivemos todo o treinamento da Universidade Sankhya, e, com isso, todos
                                                os usuários terão conhecimentos suficientes não só sobre como usar a solução no futuro, como
                                                também aprimorar os conhecimentos básicos da administração, o que faz toda a diferença para um
                                                projeto de troca de ERP bem sucedido". Bahia explica ainda que "A Sankhya oferece
                                                um ERP completo, que nos atende em todas as nossas necessidades gerenciais e operacionais, nos auxiliando
                                                a tomar decisões mais embasadas e ágeis.
                                            </p>
                                            <p>
                                                <span className="main-color">José Bahia</span><br />
                                                Gerente Financeiro
                                            </p>

                                            <div className="aspa-right">
                                                <img src={QuoteRight} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>{/* ITEM */}
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="wrap-carousel">
                            <div className="container">
                                <div className="row gx-5">
                                    <div className="col-12 col-sm-4 col-md-4">
                                        <div className="wrap-image-news">
                                            <img src={LogoNews02} alt="..." />
                                        </div>
                                    </div>
                                    <div className="col-12 col-8 col-sm-8 col-md-8">
                                        <div className="caption-news">
                                            <div className="aspa-left"> <img src={QuoteLeft} /></div>
                                            <p>
                                            Como colaboradora da empresa Safia Distribuidora de Açúcar, com o sistema implantado da Sankhya e com os vídeos da Universidade,
                                            agilizou demais nosso desempenho no trabalho, promovendo a nossa Satisfação, Rapidez, Agilidade e Eficácia.
                                            A Empresa como um todo está de Parabéns. Super recomendo e indico
                                            </p>
                                            <p>
                                                <span className="main-color">Daiana Rodrigues</span><br />
                                                Analista de Faturamento - Safia Distribuidora
                                            </p>

                                            <div className="aspa-right">
                                                <img src={QuoteRight} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>{/* ITEM */}
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="wrap-carousel">
                            <div className="container">
                                <div className="row gx-5">
                                    <div className="col-12 col-sm-4 col-md-4">
                                        <div className="wrap-image-news">
                                            <img src={LogoNews03} alt="..." />
                                        </div>
                                    </div>
                                    <div className="col-12 col-8 col-sm-8 col-md-8">
                                        <div className="caption-news">
                                            <div className="aspa-left"> <img src={QuoteLeft} /></div>
                                            <p>
                                            O que mais me encantou no sistema Sankhya e em especial a universidade, além de dar o treinamento ao colaborador,
                                            lhe oferece uma reciclagem dos principais conceitos e fluxos da área de sua atuação. E como a mesma se relaciona com as demais áreas da empresa. A Universidade ainda traz a vantagem de oferecer todo material necessário para a praticarmos o treinamento entre todas as áreas da empresa,
                                            estando todos em um único fluxo, procedimentos e assim a prática.
                                            </p>
                                            <p>
                                                <span className="main-color">Cleonice Rodrigues da Silva</span><br />
                                                Gerente financeiro Grupo São Pedro
                                            </p>

                                            <div className="aspa-right">
                                                <img src={QuoteRight} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* ITEM */}
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="wrap-carousel">
                            <div className="container">
                                <div className="row gx-5">
                                    <div className="col-12 col-sm-4 col-md-4">
                                        <div className="wrap-image-news">
                                            <img src={LogoNews04} alt="..." />
                                        </div>
                                    </div>
                                    <div className="col-12 col-8 col-sm-8 col-md-8">
                                        <div className="caption-news">
                                            <div className="aspa-left"> <img src={QuoteLeft} /></div>
                                            <p>
                                            Como diferencial da Sankhya, a Universidade Corporativa, que disponibiliza para os clientes
                                            cursos presenciais e à distância sobre o uso do software e também sobre a aplicação dos conceitos da administração no negócio.
                                            “A Universidade nos mostrou como gerir melhor os processos gerenciais”.
                                            </p>
                                            <p>
                                                <span className="main-color">Ruyter Oliveira</span><br />
                                                Gerente Administrativo - Laticínios Fleury
                                            </p>

                                            <div className="aspa-right">
                                                <img src={QuoteRight} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>{/* ITEM */}

                </div>
                <button className="carousel-control-prev seta-content-left"
                    type="button" data-bs-target="#news-carousel"
                    data-bs-slide="prev">
                    <span className="carousel-control-prev-icon"
                        aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next seta-content-right"
                    type="button" data-bs-target="#news-carousel"
                    data-bs-slide="next">
                    <span className="carousel-control-next-icon"
                        aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

        </>
    )
}

export default CarouselNews;
