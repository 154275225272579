import React from 'react';
import "./FooterSank.scss";
import LogoFooter from "assets/images/sankhya-logotipo.svg";
import IconFacebook from "assets/images/icones/social/facebook.svg";
import IconLinkedin from "assets/images/icones/social/linkedin.svg";
import IconInstagram from "assets/images/icones/social/instagram.svg";
import IconWhatsapp from "assets/images/icones/social/whatsapp.svg";

const FooterSank = () => {
    const UrlLinkedin = "https://br.linkedin.com/company/sankhyagestao/";
    const UrlInstagram = "https://www.instagram.com/sankhyagestao/";
    const UrlFacebook = "https://pt-br.facebook.com/sankhyagestao";
    const urlWhatsapp = "http://twitter.com.br";




    return (
        <>
            <footer className="footer-sank">
                <div className="container">
                    <div className="row">
                        <div className="col-7 col-lg-6">
                            <div className="icons-sociais">
                                <ul>
                                    <li> <a href={UrlLinkedin}><img src={IconLinkedin} /></a></li>
                                    <li> <a href={UrlInstagram}><img src={IconInstagram} /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-5 col-lg-6">
                            <a href="" className="logo-footer">
                                <img src={LogoFooter} alt="Logotipo" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FooterSank;
