import React from 'react';
import "./Banner.scss";

import DeskBanner01 from "assets/images/001-sk-banner.jpg";
import MobBanner01 from "assets/images/001-sk-banner-mob.jpg";
import DeskBanner02 from "assets/images/002-sk-banner.jpg";
import MobBanner02 from "assets/images/002-sk-banner-mob.jpg";

const Banner = () => {
    return (
        <>
            <div id="carouselExampleDark" className="carousel carousel-fade slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="3000">
                        <div className="wrap-banner">
                            <img src={DeskBanner01} className="banner-desk" alt="..."/>
                            <img src={MobBanner01} className="banner-mob" alt="..."/>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="carousel-caption">
                                            <h2> UNIVERSIDADE SANKHYA</h2>
                                            <p>
                                                Aprenda com mais autonomia e interatividade
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="wrap-banner">
                            <img src={DeskBanner02} className="banner-desk" alt="..."/>
                            <img src={MobBanner02} className="banner-mob" alt="..."/>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="carousel-caption">
                                            <h2>TRILHAS PERSONALIZADAS</h2>
                                            <p>
                                                De acordo com as suas necessidades de aprendizagem
                                            </p>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>


        </>

    )
}

export default Banner;
