import React from 'react'
import "./BtnSank.scss";

const BtnSank = (props) => {
    return (
        <>
            <a href={props.url} class={props.myClass}>
                {props.text}
            </a>
        </>
    )
}

export default BtnSank;
