import { Input } from 'postcss';
import React from 'react'
import "./FormSk.scss";
import {InputRadio, InputText, TextArea } from "./InputSank";

const FormSk = (props) => {
    return (
        <>
            <form className="form-contact" id={props.id}>
                <div className="row">
                    <div className="col-12">
                        <InputText type="text" placeholder="Nome" />
                    </div>
                    <div className="col-12">
                        <InputText type="email" placeholder="Email" />
                    </div>
                    <div className="col-12">
                        <InputText type="text" placeholder="Empresa" />
                    </div>
                    <div className="col-12">
                        <InputText type="tel" placeholder="Telefone" />
                    </div>
                    <div className="col-12 ">
                        <div className="labels-client">
                            <label> Já é um cliente Sankhya?</label>
                            <label htmlFor="client-yes">Sim</label>
                            <InputRadio type="radio" placeholder="Telefone" id="client-yes"/>
                            <label htmlFor="client-no">Não</label>
                            <InputRadio type="radio" placeholder="Telefone" id="client-no"/>
                        </div>
                        <TextArea placeholder="Mensagem"    />
                    </div>
                    <div className="col-12">
                        <div className="button-send">
                            <button>Ligamos para você</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default FormSk;
;
