import React from 'react';
import "./ContactSk.scss";
import "./ContactSk.js";
import FormSk from './FormSk';
const ContactSk = (props) => {
    return (
        <>
            <section id={props.id} className="contact-sk">
                <div className="container">
                    <div className="row gx-5 py-lg-5">
                        <div className="col-12 col-sm-12 col-md-6">
                            <div className="title-contact">
                                <div><h1>Ofereça a melhor capacitação para o seu time</h1></div>
                                <div><h2>Receba o nosso contato para mais informações sobre os nossos cursos.</h2></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                            <FormSk id="form-sk" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactSk;
