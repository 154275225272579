import React, { useState } from 'react';
import "./Universidade.scss";
import ItemUni from "assets/images/icones/001-trilhas-personalizadas.svg";
import ItemUni2 from "assets/images/icones/002-conteudos-de-qualidade.svg";
import ItemUni3 from "assets/images/icones/003-design-intuitivo.svg";
import ItemUni4 from "assets/images/icones/004-relatorios-de-gestao.svg";
import VideoUni from "assets/images/uni-video-01.jpg";
import ModalVideo from 'react-modal-video'

const Universidade = (props) => {
    const [ isOpen, setOpen ] = useState(false);
    return (
        <section className="uni" id={ props.id }>
            <div className="container">
                <div className="row py-2 py-lg-3">
                    <div className="col-12">
                        <div className="title-uni">
                            <h1 className="two-color">Aprenda sobre gestão e negócios com a Universidade Sankhya</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-0 py-lg-5">
                <div className="row gx-5">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <div className="card-uni">
                            <div className="card-uni-image">
                                <img src={ ItemUni }/>
                            </div>
                            <div className="card-uni-text">
                                Trilhas personalizadas
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <div className="card-uni">
                            <div className="card-uni-image">
                                <img src={ ItemUni2 }/>
                            </div>
                            <div className="card-uni-text">
                                Conteúdos de qualidade
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <div className="card-uni">
                            <div className="card-uni-image">
                                <img src={ ItemUni3 }/>
                            </div>
                            <div className="card-uni-text">
                                Design Intuitivo
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <div className="card-uni">
                            <div className="card-uni-image">
                                <img src={ ItemUni4 }/>
                            </div>
                            <div className="card-uni-text">
                                Relatórios de gestão
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uni-video">
                <div className="container">
                    <div className="row">
                        <div className="col-12 pb-1 pb-lg-5">
                            <div className="title-uni">
                                <h1 className="white-color"> Conheça a Universidade Sankhya</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-6">
                            <div className="box-video">
                                <ModalVideo channel='youtube' autoplay
                                            isOpen={ isOpen }
                                            videoId="rSwWp9NfVfg"
                                            onClose={ () => setOpen(false) }/>
                                <button className="btn-primary"
                                        onClick={ () => setOpen(true) }><img
                                    src={ VideoUni }/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Universidade;
