import React from 'react'
import { LineHeadMenu } from './LineHeadMenu';

const HeaderPublic = () => {
    return (
        <>
            <header>
                <LineHeadMenu />
            </header>

        </>
    )
}

export default HeaderPublic;
