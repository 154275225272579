import React from 'react';
import "./InputSank.scss";

export const InputText = (props) => {
    return (
        <input className='input-text' type={props.type} placeholder={props.placeholder} id={props.id} />
    )
}

export const InputRadio = (props) => {
    return (
        <input className='input-radio'type={props.type} placeholder={props.placeholder} id={props.id} />
    )
}

export const TextArea = (props) =>{
    return (
        <textarea placeholder={props.placeholder} className="text-area">
            {props.children}
        </textarea>
    )
}
